import React, { useState, useEffect } from 'react';
import './App.css';
import { ZoomMtg } from '@zoom/meetingsdk';
import logo from './logo.png';
import img from './iit-k.jpeg';
const KJUR = require('jsrsasign');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

function App() {
    const [meetingId, setMeetingId] = useState('');
    let [meetingPassword, setMeetingPassword] = useState('');
    const [username, setUsername] = useState('');
   
    const sdkKey = 'NKWoeN568Ev8ErvhwOloRFOLBfWDF2fYhSBf';
    const apiSecret = 'BcIIh8QnG2zKLkbTHf4RUJTR5YNEUo0WZo2j';
    const role = 0;
    const leaveUrl = 'http://call.workerunionsupport.com/';

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const meetingIdParam = params.get('meeting_id');
        const meetingPasswordParam = params.get('meeting_password');

        if (meetingIdParam) {
            setMeetingId(meetingIdParam);
        }

        if (meetingPasswordParam) {
           
            setMeetingPassword(meetingPasswordParam);
        }
    }, []);


    function getSignature(e) {
        e.preventDefault();
        generateSignature(sdkKey, apiSecret, meetingId, role);
    }

    function generateSignature(apiKey, apiSecret, meetingNumber, role) {
        const iat = Math.round(new Date().getTime() / 1000) - 30;
        const exp = iat + 60 * 60 * 2;

        const oHeader = { alg: 'HS256', typ: 'JWT' };

        const oPayload = {
            sdkKey: apiKey,
            mn: meetingNumber,
            role: role,
            iat: iat,
            exp: exp,
            appKey: apiKey,
            tokenExp: iat + 60 * 60 * 2,
        };

        const sHeader = JSON.stringify(oHeader);
        const sPayload = JSON.stringify(oPayload);
        const signature = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, apiSecret);

        startMeeting(signature);
    }

    function startMeeting(signature) {
        document.getElementById('zmmtg-root').style.display = 'block';

        ZoomMtg.init({
            leaveUrl: leaveUrl,
            patchJsMedia: true,
            leaveOnPageUnload: true,
            success: (success) => {
                console.log(success);
                if (meetingPassword === 'wus') {
                    meetingPassword += '24';
                }
                ZoomMtg.join({
                    signature: signature,
                    sdkKey: sdkKey,
                    meetingNumber: meetingId,
                    passWord: meetingPassword,
                    userName: username,
                    userEmail: '',
                    tk: '',
                    zak: '',
                    success: (success) => {
                        console.log(success);
                    },
                    error: (error) => {
                        console.log(error);
                    },
                });
            },
            error: (error) => {
                console.log(error);
            },
        });
    }

    return (
        <div className="App">
            <main>
                <form className="containerap" onSubmit={getSignature}>
                    <div className="centerDiv">
                        <img src={logo} className="App-logo" alt="logo" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span> <img src={img} className="App-img" alt="img" /></span>
                    </div>
                    <div className="centerDiv">
                        <h1>WUS Conference</h1>
                    </div>
                    <div>
                        <label>Enter Your Meeting ID:</label>
                        <input
                            type="text"
                            value={meetingId}
                            onChange={(e) => setMeetingId(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Enter Your Meeting Password:</label>
                        <input
                            type="text"
                            value={meetingPassword}
                            onChange={(e) => setMeetingPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Enter Your Name:</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Join Meeting</button>
                </form>
            </main>
        </div>
    );
}

export default App;
